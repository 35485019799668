<template>
  <LayoutSection :id="id" :title="sectionTitle" padding>
    <BlokkliField
      :list="paragraphs"
      class="grid grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-40"
      name="field_paragraphs_teaser"
    />
  </LayoutSection>
</template>

<script lang="ts" setup>
import type { ParagraphTeaserListFragment } from '#graphql-operations'
import { slugify } from '~/helpers/slugify'

const { options } = defineBlokkli({
  bundle: 'teaser_list',
  globalOptions: ['teaserStyle', 'showInToc', 'bkVisibleLanguages'],
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
  },
})

const props = defineProps<{
  title?: string
  paragraphs?: ParagraphTeaserListFragment['paragraphs']
}>()

const teaserStyle = computed(() => options.value.teaserStyle)

provide('paragraphTeaserStyle', teaserStyle)

const { $texts } = useNuxtApp()

const sectionTitle = computed(() => {
  return props.title || $texts('teaserListTitle', 'Aktuelles')
})

const id = computed(() => slugify(sectionTitle.value))

defineOptions({
  name: 'ParagraphTeaserList',
})
</script>
