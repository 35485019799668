<template>
  <RenderWithDependency
    :dependency="person || organisation"
    :class="{ 'container my-30 paragraph--margin': fieldListType !== 'box' }"
    class="not-last:mb-30"
  >
    <!-- Government Contact Person -->
    <TaxonomyTermPerson
      v-if="person"
      v-slot="{ fullName, phoneNumber, role, mail, address, image }"
      :person="person"
    >
      <div
        v-if="options.format === 'full' && image !== null"
        class="w-[180px] h-auto overflow-hidden mb-10 rounded"
      >
        <img :src="image" loading="lazy" :alt="fullName" />
      </div>
      <div v-if="fullName" class="font-bold">{{ fullName }}</div>
      <div v-if="role" class="mb-10">{{ role }}</div>
      <template v-if="showContactDetails">
        <FieldItemTypeAddress
          v-if="address && options.format === 'full'"
          v-bind="address"
          class="my-20"
        />
        <DrupalPhoneNumber
          v-if="phoneNumber"
          v-bind="phoneNumber"
          class="button mt-5 mr-10 is-link is-action"
        />

        <a
          v-if="mail"
          :href="'mailto:' + mail"
          class="button mt-5 is-link is-action"
        >
          {{ mail }}
        </a>
      </template>
    </TaxonomyTermPerson>

    <!-- Government Contact Organisation -->
    <TaxonomyTermOrganizationUnit
      v-if="organisation"
      v-bind="organisation"
      :hide-map="options.format !== 'full'"
      :hide-opening-hours="options.format === 'minimal'"
    />
  </RenderWithDependency>
</template>

<script lang="ts" setup>
import type { ParagraphGovernmentContactFragment } from '#graphql-operations'

const { fieldListType, options } = defineBlokkli({
  bundle: 'government_contact',
  globalOptions: ['bkVisibleLanguages'],
  options: {
    format: {
      type: 'radios',
      label: 'Darstellung',
      default: 'reduced',
      options: {
        minimal: 'Minimal',
        reduced: 'Reduziert',
        full: 'Komplett',
      },
    },
  },
  editor: {
    editTitle: (el) =>
      // @ts-ignore
      el.querySelector('div.font-bold')?.innerText,
    mockProps: (text) => {
      return {
        person: {
          firstName: text || 'Name der Person',
          mail: 'vorschau@bs.ch',
          role: 'Lorem ipsum',
        },
      }
    },
  },
})

defineProps<{
  person?: ParagraphGovernmentContactFragment['person']
  organisation?: ParagraphGovernmentContactFragment['organisation']
}>()

const showContactDetails = computed(
  () => options.value.format === 'full' || options.value.format === 'reduced',
)

defineOptions({
  name: 'ParagraphGovernmentContact',
})
</script>
