<template>
  <VuepalLink
    :to="to"
    class="group/button group/link hyphens-auto flex flex-col"
  >
    <h3 class="text-lg font-bold link order-2">
      {{ title }}
    </h3>
    <div class="mb-20 bg-gray-50 order-1">
      <MediaImage
        v-if="media"
        rounded
        v-bind="media"
        :image-style="imageStyle"
        hide-caption
        img-class="w-full"
      />
    </div>
    <p
      v-if="text && !square"
      class="mt-10 text-blue-900 group-hover/button:text-blue-700 order-3"
    >
      {{ text }}
    </p>
    <div class="button is-link mt-15 order-4">
      {{ $texts('details', 'Details') }}
      <span class="sr-only">{{ detailsSrText }}</span>
    </div>
  </VuepalLink>
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaImageFragment } from '#graphql-operations'
import type { NodeBundle } from '~/types/drupal'

const props = defineProps<{
  title: string
  text?: string
  to?: string
  media?: MediaImageFragment
  isExternal?: string

  // Square style.
  square?: boolean
  toNodeBundle?: NodeBundle
}>()

const imageStyle = computed<DefineImageStyleConfig>(() =>
  defineImageStyle({
    type: 'sizes',
    aspectRatio: props.square ? 1 : 16 / 9,
    sizes: {
      sm: 382,
      md: 224,
      lg: 252,
      xl: 334,
    },
  }),
)

const { $texts } = useNuxtApp()

const detailsSrText = computed(() => {
  switch (props.toNodeBundle) {
    case 'event':
      return $texts('accessibility.forThisEvent', 'zu dieser Veranstaltung')
    case 'filter_page':
      return $texts('accessibility.forThisFilterPage', 'zu dieser Filterseite')
    case 'garbage_material':
      return $texts(
        'accessibility.forThisGarbageMaterial',
        'zu diesem Abfallmaterial',
      )
    case 'news':
      return $texts('accessibility.forThisNews', 'zu dieser News')
    case 'organization_page':
      return $texts(
        'accessibility.forThisOrganizationPage',
        'zu dieser Organisationsseite',
      )
    case 'page':
      return $texts('accessibility.forThisPage', 'zu dieser Seite')
    case 'press_release':
      return $texts(
        'accessibility.forThisPressRelease',
        'zu dieser Medienmitteilung',
      )
    case 'topic':
      return $texts('accessibility.forThisTopic', 'zu diesem Thema')
    case 'publication':
      return $texts('accessibility.forThisPublication', 'zu dieser Publikation')
    case 'person':
      return $texts('accessibility.forThisPerson', 'zu dieser Person')
    case 'filter_page_item':
    default:
      return $texts('accessibility.forThisContent', 'zu diesem Inhalt')
  }
})
</script>
