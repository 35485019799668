<template>
  <a v-if="parsed" :href="parsed.rfc3966">
    <slot>
      {{ parsed.international }}
    </slot>
  </a>
</template>

<script lang="ts" setup>
import type { FieldItemTypeTelephoneFragment } from '#graphql-operations'

defineProps<{
  parsed?: FieldItemTypeTelephoneFragment['parsed']
}>()
</script>

<script lang="ts">
export default {
  name: 'DrupalPhoneNumber',
}
</script>
