<template>
  <Accordion v-if="title" :title="title" :always-open="isEditing">
    <template #title>
      <span v-blokkli-editable:field_title>{{ title }}</span>
    </template>
    <div
      v-if="description"
      v-blokkli-editable:paragraphs_text="{ type: 'frame' }"
      class="ck-content text-base font-normal mb-20"
      v-html="description"
    />

    <div v-if="links?.length" class="grid gap-5">
      <FieldItemTypeLinkDescription
        v-for="(link, key) in links"
        :key="key"
        v-bind="link"
        class="link with-icon"
      />
    </div>
  </Accordion>
</template>

<script lang="ts" setup>
import type { ParagraphInformationLinkAttachmentFragment } from '#graphql-operations'

const { isEditing } = defineBlokkli({
  bundle: 'information_link_attachment',
  renderFor: [
    {
      parentBundle: 'expand_section',
    },
  ],
  editor: {
    editTitle: (el) => el.querySelector('span')?.innerText,
  },
})

defineProps<{
  title: ParagraphInformationLinkAttachmentFragment['title']
  description?: ParagraphInformationLinkAttachmentFragment['description']
  links: ParagraphInformationLinkAttachmentFragment['links']
}>()
</script>
