<template>
  <div v-if="link?.uri?.path || isEditing" class="h-full flex flex-col">
    <h2
      v-blokkli-editable:field_title
      class="text-lg font-bold mb-[16px] text-white text-balance"
    >
      {{ title }}
    </h2>
    <p
      v-blokkli-editable:field_text
      class="text-base mb-30 text-white hyphens-auto"
    >
      {{ text }}
    </p>
    <VuepalLink
      v-if="link?.uri"
      :to="link.uri.path"
      class="button is-super is-link is-inverted mt-auto"
    >
      {{ link?.title || $texts('learnMore', 'Mehr erfahren') }}
    </VuepalLink>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphNextBestActionFragment } from '#graphql-operations'

const { isEditing } = defineBlokkli({
  bundle: 'next_best_action',
  globalOptions: ['bkVisibleLanguages'],
  editor: {
    previewBackgroundClass: 'bg-blue-900',
    previewWidth: 290,
    editTitle: (el) => el.querySelector('h2')?.innerText,
  },
})

defineProps<{
  title?: ParagraphNextBestActionFragment['title']
  text?: ParagraphNextBestActionFragment['text']
  link?: ParagraphNextBestActionFragment['link']
}>()

defineOptions({
  name: 'ParagraphNextBestAction',
})
</script>
