<template>
  <LayoutSection
    :id="id"
    :hero-title="title"
    :lead="description"
    editable-hero-title="field_section_title"
    editable-lead="field_section_description"
    bg-primary-light
    padding
    class="mt-30 paragraph-expand-section paragraph--padded"
  >
    <BlokkliField
      :list="paragraphs"
      name="field_accordions"
      class="grid gap-10 md:gap-15 grid-cols-1 items-start"
      :class="{ 'md:grid-cols-2': options.columns === 'two' }"
    />
  </LayoutSection>
</template>

<script lang="ts" setup>
import type { ParagraphExpandSectionFragment } from '#graphql-operations'
import { slugify } from '~/helpers/slugify'

const { options } = defineBlokkli({
  bundle: 'expand_section',
  globalOptions: ['showInToc', 'bkVisibleLanguages'],
  options: {
    columns: {
      type: 'radios',
      label: 'Spalten',
      default: 'one',
      displayAs: 'grid',
      options: {
        one: { columns: [1], label: '1' },
        two: { columns: [1, 1], label: '2' },
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
  },
})

const props = defineProps<{
  title?: ParagraphExpandSectionFragment['title']
  description?: ParagraphExpandSectionFragment['description']
  paragraphs?: ParagraphExpandSectionFragment['paragraphs']
}>()

const id = computed(() => slugify(props.title))

defineOptions({
  name: 'ParagraphExpandSection',
})
</script>

<style lang="postcss">
.paragraph-expand-section + .paragraph-expand-section {
  @apply mt-0;
}
</style>
