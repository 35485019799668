<template>
  <h2
    v-if="title || isEditing"
    :id="id"
    class="container paragraph--margin h2 mb-20 lg:mb-30 mt-10 md:mt-40 xl:mt-50 scroll-mt-10 xl:pr-140"
  >
    <span
      v-blokkli-editable:field_title="{ required: true }"
      class="inline-block"
      >{{ title }}</span
    >
  </h2>
</template>

<script lang="ts" setup>
import { slugify } from '~/helpers/slugify'

const { isEditing } = defineBlokkli({
  bundle: 'section_title',
  globalOptions: ['showInToc', 'bkVisibleLanguages'],
  editor: {
    editTitle: (el) => el.innerText,
    getDraggableElement: (el) => el.querySelector('span'),
  },
})

const props = defineProps<{
  title: string
  anchor?: string
}>()

const id = computed(() => slugify(props.anchor || props.title))

defineOptions({
  name: 'ParagraphSectionTitle',
})
</script>
