<template>
  <div class="container paragraph--margin">
    <hr class="border-t-gray-200 pt-10 block w-full mt-10 -mb-10" />
  </div>
</template>

<script lang="ts" setup>
/**
 * This paragraph needs a padding bottom so that is can be selected in blökkli.
 * To compensate, it adds a negative margin at the bottom as well so that the
 * spacing remains consistent.
 */
defineBlokkli({
  bundle: 'horizontal_line',
  editor: {
    disableEdit: true,
    addBehaviour: 'no-form',
  },
})

defineOptions({
  name: 'ParagraphHorizontalLine',
})
</script>
