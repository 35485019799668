export function buildSectionTitle(selectedContentTypes: string[]): string {
  const { $texts } = useNuxtApp()

  const titleParts: string[] = []

  // Handle special overrides.
  if (
    selectedContentTypes.length > 1 &&
    selectedContentTypes.includes('witness_calls')
  ) {
    return $texts('dynamicTeaserList.latestNews', 'Aktuelles')
  }

  if (selectedContentTypes.includes('witness_calls')) {
    titleParts.push($texts('witnessCalls', 'Zeugenaufrufe'))
  }

  if (selectedContentTypes.includes('press_release')) {
    titleParts.push($texts('pressReleases', 'Medienmitteilungen'))
  }

  if (selectedContentTypes.includes('news')) {
    titleParts.push($texts('news', 'News'))
  }

  return titleParts.join(' & ')
}
