<template>
  <a
    v-if="imageUrl && linkUrl"
    class="flex w-full aspect-[343/160] border border-gray-300 rounded overflow-hidden group hover:border-gray-500 relative items-center justify-center bg-gray-100"
    :href="linkUrl"
    rel="nofollow"
    target="_blank"
  >
    <img
      :src="imageUrl"
      class="absolute size-full object-cover top-0 left-0"
      loading="lazy"
      :alt="$texts('accessibility.mapOfBs', 'Karte von Basel-Stadt')"
    />

    <img
      src="~/assets/icons/map-pin-coloured.svg"
      class="relative z-40 mb-[3%] pointer-events-none group-hover:animate-jump-y origin-bottom"
      alt=""
      loading="lazy"
      aria-hidden="true"
    />

    <div
      class="absolute top-0 left-0 size-full bg-blue-200 z-10 mix-blend-overlay pointer-events-none opacity-0 transition group-hover:opacity-40"
    />
    <span class="sr-only"> {{ label }}. </span>
    <span class="sr-only">
      {{
        $texts(
          'accessibility.externalLinkText',
          'Externer Link, wird in einem neuen Tab oder Fenster geöffnet',
        )
      }}
    </span>
  </a>
</template>

<script setup lang="ts">
defineProps<{
  linkUrl?: string
  imageUrl?: string
}>()

const { $texts } = useEasyTexts()

const label = computed(() => $texts('toMapBs', 'Zur Karte von MapBS'))
</script>
