<template>
  <Component :is="tag" v-if="isEditing || dependency">
    <slot v-if="dependency" />
    <div
      v-if="isEditing && !dependency"
      class="bg-red-100 flex items-center justify-center text-red-700 font-bold text-2xl px-20 py-30"
    >
      {{ message }}
    </div>
  </Component>
</template>

<script lang="ts" setup>
import { INJECT_BLOCK_ITEM } from '#blokkli/helpers/symbols'
import type { InjectedBlokkliItem } from '#blokkli/types'

withDefaults(
  defineProps<{
    dependency: boolean | object | string | number | undefined | null
    tag?: string
    message?: string
  }>(),
  {
    tag: 'div',
    message: 'Inhalt nicht gefunden.',
  },
)

const item = inject<InjectedBlokkliItem>(INJECT_BLOCK_ITEM)

const isEditing = computed(() => !!item?.value.isEditing)
</script>
