<template>
  <div>
    <MapImage
      v-if="mapLocation && !hideMap"
      v-bind="mapLocation"
      class="mb-10 lg:mb-15 max-w-[468px]"
    />

    <h3 v-if="!hideTitle && label" class="font-bold mb-2">
      {{ label }}
    </h3>

    <FieldItemTypeAddress v-if="address" v-bind="address" class="mb-15" />

    <div v-if="openingHours && !hideOpeningHours" class="my-15">
      <h3 class="text-primary-700 font-bold text-xs mb-2">
        {{ $texts('openingHours', 'Öffnungszeiten') }}
      </h3>
      <div class="text-gray-900" v-html="openingHours" />
    </div>

    <div v-if="!address && portrait?.first?.value">
      <h3 class="text-primary-700 font-bold text-xs">
        {{ $texts('Portrait', 'Porträt') }}
      </h3>
      <div class="text-gray-900" v-html="portrait?.first?.value" />
    </div>

    <div v-if="mail || phoneNumber?.first" class="flex gap-10 flex-wrap">
      <DrupalPhoneNumber
        v-if="phoneNumber?.first"
        v-bind="phoneNumber.first"
        class="button is-action is-link whitespace-nowrap"
      />

      <a
        v-if="mail"
        :href="`mailto:${mail}`"
        class="button is-action is-link whitespace-nowrap"
      >
        {{ mail }}
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { TaxonomyTermOrganizationUnitFragment } from '#graphql-operations'

const props = defineProps<{
  // Props from the fragment.
  label?: TaxonomyTermOrganizationUnitFragment['label']
  phoneNumber?: TaxonomyTermOrganizationUnitFragment['phoneNumber']
  mail?: TaxonomyTermOrganizationUnitFragment['mail']
  openingHours?: TaxonomyTermOrganizationUnitFragment['openingHours']
  address?: TaxonomyTermOrganizationUnitFragment['address']
  location?: TaxonomyTermOrganizationUnitFragment['location']
  portrait?: TaxonomyTermOrganizationUnitFragment['portrait']
  overwriteMapCoordinates?: TaxonomyTermOrganizationUnitFragment['overwriteMapCoordinates']
  customMapCoordinates?: TaxonomyTermOrganizationUnitFragment['customMapCoordinates']

  // Component specific props.
  hideTitle?: boolean
  hideMap?: boolean
  hideOpeningHours?: boolean
}>()

defineOptions({
  name: 'TaxonomyTermOrganizationUnit',
})

const mapLocation = computed(() => {
  if (props.overwriteMapCoordinates) {
    return props.customMapCoordinates
  }

  return props.location
})
</script>
