<template>
  <div>This is just a test fragment.</div>
</template>

<script lang="ts" setup>
defineBlokkliFragment({
  name: 'test_fragment',
  label: 'Test Fragment',
})
</script>
