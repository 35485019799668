<template>
  <div class="container mt-20 mb-30 paragraph--margin">
    <ul class="grid md:grid-cols-2 gap-20 lg:gap-y-25 xl:gap-y-30">
      <li v-for="(link, index) in linkitems" :key="index">
        <FieldItemTypeLinkDescription
          v-bind="link"
          class="link with-icon lg:text-lg"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphLinkListFragment } from '#graphql-operations'

defineBlokkli({
  bundle: 'link_list',
  globalOptions: ['bkVisibleLanguages'],
  editor: {
    editTitle: (el) => el.querySelector('a')?.innerText,
  },
})

defineProps<{
  linkitems?: ParagraphLinkListFragment['linkitems']
}>()

defineOptions({
  name: 'ParagraphLinkList',
})
</script>
