<template>
  <LayoutSection
    :id="id"
    padding
    :title="$texts('homePage.titleFocusInformations', 'Schwerpunkt')"
    :bg-blue="isDark"
    :bg-primary-light="!isDark"
    class="paragraph--padded"
  >
    <h3
      v-blokkli-editable:field_title
      class="font-bold md:text-5xl text-3xl text-primary-500 pb-25"
      :class="{
        'text-white': isDark,
      }"
    >
      {{ title }}
    </h3>
    <section
      :class="{
        'gap-20 grid md:grid-cols-3': media,
      }"
    >
      <component
        :is="mainLink ? DrupalLink : 'div'"
        :to="mainLink ? mainLink?.uri?.path : undefined"
        class="md:pb-0 md:col-span-2"
      >
        <MediaImage
          v-if="media"
          v-bind="media"
          :image-style="imageStyle"
          rounded
          img-class="w-full"
          :class="{
            'text-white': isDark,
          }"
        />
      </component>
      <div :class="{ 'md:pt-0': media }">
        <ul
          class="grid gap-20 grid-cols-1"
          :class="{
            'md:grid-cols-3': !media,
          }"
        >
          <li v-for="(link, index) in props.links" :key="index">
            <FieldItemTypeLinkDescription
              hide-description
              v-bind="link"
              class="link with-icon xl:text-lg"
              :class="{ 'is-inverted': isDark }"
            />
          </li>
        </ul>
        <VuepalLink
          v-if="mainLink"
          :to="mainLink?.uri?.path"
          class="button mt-20 is-link"
          :class="{ 'is-inverted': isDark }"
        >
          {{ $texts('learnMore', 'Mehr erfahren') }}
        </VuepalLink>
      </div>
    </section>
  </LayoutSection>
</template>

<script lang="ts" setup>
import type { ParagraphFocusTeaserFragment } from '#graphql-operations'
import { DrupalLink } from '#components'
import { slugify } from '~/helpers/slugify'

// TODO: Link style in blue background

const { options, uuid } = defineBlokkli({
  bundle: 'focus_teaser',
  globalOptions: ['bkVisibleLanguages'],
  options: {
    type: {
      type: 'radios',
      label: 'Farbe',
      default: 'navigation',
      displayAs: 'colors',
      options: {
        darkBlue: { class: 'bg-blue-900', label: 'Blau' },
        lightGreen: { class: 'bg-primary-100', label: 'Grün' },
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
  },
})

const props = defineProps<{
  title?: ParagraphFocusTeaserFragment['title']
  media?: ParagraphFocusTeaserFragment['media']
  mainLink?: ParagraphFocusTeaserFragment['mainLink']
  links?: ParagraphFocusTeaserFragment['links']
}>()

const isDark = computed(() => options.value.type === 'darkBlue')

const id = computed(() => slugify(props.title || uuid))

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    sm: 396,
    md: 480,
    lg: 524,
    xl: 687,
  },
})

defineOptions({
  name: 'ParagraphFocusTeaser',
})
</script>
