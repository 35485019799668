<template>
  <div
    class="hidden container data-[editing=true]:block data-[editing=true]:py-10 paragraph--margin"
    aria-hidden="true"
  >
    Das Zuzug-Chatbot-Fragment, das das Skript enthält, ist hier enthalten.
  </div>
</template>

<script lang="ts" setup>
declare global {
  interface Window {
    aiaibot?: {
      hide: () => void
      show: () => void
    }
  }
}

const { isEditing } = defineBlokkliFragment({
  name: 'zuzug',
  label: 'Zuzug Chatbot',
})

const isMounted = ref(false)
const { setActiveChatbot } = useChatbot()
const { isAcceptAll, isPartiallyAccepted } = useConsentCookie()
setActiveChatbot('zuzug')

const shouldInject = computed(
  () =>
    (isAcceptAll.value || isPartiallyAccepted.value) &&
    !isEditing &&
    isMounted.value,
)

useScript(
  {
    src: 'https://chat.aiaibot.com/bootstrap.js',
    async: true,
    defer: true,
    crossorigin: false,
    'data-aiaibot-key': '8ba69b80-4f4f-4f39-aadd-ebaa131fb7dd',
  },
  {
    trigger: useScriptTriggerConsent({
      consent: shouldInject,
    }),
  },
)

onMounted(() => {
  if (window && window.aiaibot) {
    window.aiaibot.show()
  }

  isMounted.value = true
})

onBeforeUnmount(() => {
  isMounted.value = false
  setActiveChatbot(null)
})

onUnmounted(() => {
  if (window && window.aiaibot) {
    window.aiaibot.hide()
  }
})

defineOptions({
  name: 'ParagraphBlokkliFragmentZuzug',
})
</script>
