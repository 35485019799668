<template>
  <container>
    <link href="https://map.geo.bs.ch/api.css" rel="stylesheet" />
    <div id="map1" style="width: 500px; height: 500px" />
  </container>
</template>

<script lang="ts" setup>
defineBlokkliFragment({
  name: 'sound_of_religion',
  label: 'Sound of religion',
})

onMounted(() => {
  const externalScript = document.createElement('script')
  externalScript.type = 'text/javascript'
  externalScript.src = 'https://map.geo.bs.ch/api.js?version=2'
  externalScript.onload = function () {
    console.log('MapBS JS library loaded')
    const inlineScript = document.createElement('script')
    inlineScript.type = 'text/javascript'
    inlineScript.text = `
      var map = new mapbs.Map({div: 'map1', zoom: 4, center: [2611484, 1267592]});
      map.addCustomLayer('text', 'data', '/standalone-html-pages/sound-of-religion/data.htm', {
        success: function() {
          map.selectObject(1);
        }
      });
      console.log('Sound of religion done');
    `
    document.body.appendChild(inlineScript)
  }
  document.body.appendChild(externalScript)
})
</script>
