<template>
  <slot
    v-if="person"
    :full-name="fullName"
    :first-name="person.firstName"
    :last-name="person.lastName"
    :mail="person.mail"
    :phone-number="phoneNumber"
    :role="person.role"
    :address="person.address"
    :image="person.image"
  />
</template>

<script lang="ts" setup>
import type { TaxonomyTermPersonFragment } from '#graphql-operations'

const props = defineProps<{
  person?: TaxonomyTermPersonFragment
}>()

const fullName = computed(() => {
  if (!props.person) {
    return ''
  }
  return [props.person.firstName, props.person.lastName]
    .filter(Boolean)
    .join(' ')
})

const phoneNumber = computed(() => {
  return props.person?.fieldPhoneNumber?.first
})
</script>
