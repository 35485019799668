<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <div @click.capture="onClick" @keydown.enter="onClick">
    <slot />
  </div>
</template>

<script setup lang="ts">
const { onClick } = useClickTriggerProxy()
</script>
