<template>
  <RenderWithDependency
    :dependency="image"
    class="not-last:mb-20 xl:not-last:mb-30 not-first:mt-20 xl:not-first:mt-40 paragraph--margin"
    message="Bild nicht gefunden. Bitte überprüfen Sie ob das Bild ggf. gelöscht wurde."
    :class="{ container: !parentType }"
  >
    <MediaImage
      v-blokkli-droppable:field_image
      v-bind="image"
      :image-style="imageStyle"
      img-class="w-full"
      loading="lazy"
      rounded
    />
  </RenderWithDependency>
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaImageFragment } from '#graphql-operations'

const { options, parentType } = defineBlokkli({
  bundle: 'image',
  globalOptions: ['bkVisibleLanguages'],
  options: {
    format: {
      type: 'radios',
      label: 'Bildformat',
      default: 'wide',
      options: {
        square: 'Quadratisch',
        wide: 'Quer',
        original: 'Original',
      },
    },
  },
  editor: {
    editTitle: (el) => {
      const img = el.querySelector('img')
      return img?.alt || img?.title
    },
    getDraggableElement: (el) => el.querySelector('figure'),
  },
})

defineProps<{
  image?: MediaImageFragment
}>()

const imageStyleSquare = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 400,
    md: 728,
    lg: 796,
    xl: 1040,
  },
})

const imageStyleOriginal = defineImageStyle({
  type: 'sizes',
  sizes: {
    sm: 400,
    md: 728,
    lg: 796,
    xl: 1040,
  },
})

/**
 * The responsive image style name to use.
 */
const imageStyle = computed<DefineImageStyleConfig | 'header'>(() => {
  if (options.value.format === 'square') {
    return imageStyleSquare
  } else if (options.value.format === 'original') {
    return imageStyleOriginal
  }
  return 'header'
})

defineOptions({
  name: 'ParagraphImage',
})
</script>
