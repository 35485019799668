<template>
  <div class="container my-30" :class="{ 'paragraph--margin': !parentType }">
    <InformationLinkAttachment
      :title="title"
      :description="description"
      :links="links"
      :is-inverted="isInverted"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphInformationLinkAttachmentFragment } from '#graphql-operations'

const { options, parentType } = defineBlokkli({
  bundle: 'information_link_attachment',
  globalOptions: ['bkVisibleLanguages'],
  options: {
    type: {
      type: 'radios',
      label: 'Farbe',
      default: 'lightGreen',
      displayAs: 'colors',
      options: {
        darkGreen: { class: 'bg-primary-600', label: 'Dunkel' },
        lightGreen: { class: 'bg-primary-100', label: 'Hell' },
      },
    },
  },
  editor: {
    getDraggableElement: (el) => {
      return el.querySelector('.box-paragraph')
    },
    editTitle: (el) =>
      el.querySelector('summary')?.innerText ||
      el.querySelector('h3')?.innerText,
  },
})

defineProps<{
  title?: ParagraphInformationLinkAttachmentFragment['title']
  description?: ParagraphInformationLinkAttachmentFragment['description']
  links?: ParagraphInformationLinkAttachmentFragment['links']
}>()

const isInverted = computed(() => options.value.type === 'darkGreen')

defineOptions({
  name: 'ParagraphInformationLinkAttachment',
})
</script>
